<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('goals.new')">
        <actions
          slot="actions"
          crud-links="goals"
          :actions="actions"
        />
        <data-form
          :goal="goal"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'goals-new',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      goal: {},
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'goals/'
    },
    async submit (goal) {
      if (this.loading) {
        return
      }
      this.loading = true

      try {
        await this.$http.post(this.routeBuilder(), goal)
      } catch (e) {
        // console.log('Error while creating goal', e)
        this.loading = false
        return
      }

      this.$router.push({ name: 'goalsIndex' })
    },
  },
}
</script>
